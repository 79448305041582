#burger {
  position: fixed;
  top: 5%;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 12;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    height: 0.25rem;
    background: #112049;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
}
#burger-overlay {
  position: fixed;
  overflow-y: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: rgba(#008df6, 0.95);
  ul {
    align-self: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.hide {
  display: none;
  opacity: 0;
}
.fade-in {
  display: flex;
  visibility: visible;
  opacity: 1;
  scale: 1;
  animation: fade-in 0.6s linear;
}

.fade-out {
  opacity: 0;
  visibility: hidden;
  transition: visibility 1s linear;
  animation: fade-out 0.6s linear;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.2);
  }
}
