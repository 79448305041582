li {
  display: inline;
  background-color: transparent;
  font-size: 24pt;
  margin-right: 16px;
}

ul {
  display: flex;
  position: absolute;
  margin-top: 0;
  width: 100%;
  text-align: right;
  padding: 18px;
  z-index: 2;
  top: 0;
  right: 0;
}

.solid {
  background-color: #112049;
  opacity: 0.6;
}
.active-nav-link {
  font-weight: 600;
  border-bottom: 3pt solid white;
  text-decoration-line: none;
}
a:link,
a:visited {
  color: white;
  font-weight: 600;
}

.nav-link {
  transition: border-width 0.2s linear;
  color: white;
}

.nav-link:focus {
  color: white !important;
}
.nav-link:hover {
  text-decoration-line: none;
  color: white !important;
  border-bottom: 3pt solid white;
}

.logo {
  position: fixed;
  height: 400pt;
  object-fit: contain;
}
