body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.section-header {
  margin-top: 0px;
  font-size: 48pt;
  align-self: center;
}

/* Tablet */
@media only screen and (max-width: 1024px) {
  .section-header {
    font-size: 36pt;
  }
}

section {
  display: flex;
  min-height: 100vh;
}

section > p {
  max-width: 1200px;
  margin: 36px 36px;
}
